<template>
  <div class="orders col-xl-6 col-sm-6 pl-sm-2">
    <div class="divider divider_bold ">
      <div class="text_white titlePage_subtitle titlePage_bold">
        <h3>MY STATISTICS</h3>
      </div>
    </div>
    <div v-if="!loaded">
      <svg-icon name="spinner" class="tw-animate-spin"/>
      Loading data. Please wait
    </div>

    <div class="orders_list py-1" v-else>
      <h3 class="text_green">MY JOBS</h3>
      <ul class="pr-3">
        <li v-for="(v, i) in statistics.orders">
          {{i}} <span> {{$filters.formatMoney(v)}} </span>
        </li>
      </ul>
      <h3 class="text_green">Pending Quotes And Estimates</h3>
      <ul class="pr-3">
        <li v-for="(v, i) in statistics.finances">
          {{i}} <span> {{$filters.formatMoney(v)}} </span>
        </li>
        <!--<li>Pending Estimates <span>${{statistics.finances.estimate}}</span></li>-->
        <!--<li>Pending Quotes <span>${{statistics.finances.quotes}}</span></li>-->
        <!--<li>Orders This Month  <span>${{statistics.finances.month}}</span></li>-->
        <!--<li>Orders This Year <span>${{statistics.finances.year}}</span></li>-->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Sales',
  computed: {
    statistics() {
      return this.$store.getters['userStatistics/statistics'];
    },
      loaded() {
          return this.$store.getters['userStatistics/loaded'];
      },
  },
  mounted(){
      this.$store.dispatch('userStatistics/loadStatistics');
  }
};
</script>

<style scoped lang="scss">
  .titlePage_subtitle {
    padding-left: 20px;
  }
  .orders_list li{
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem;
    padding-left: 1rem;
  }
  .orders_list h3 {
    border-bottom: 2px solid #0b3553;
    margin-bottom: 1rem;
  }

  .divider{
  &:before{
     display: none;
   }
  }
</style>
